<template>
    
    <div class="property_item heading_space">
        <router-link v-if="$i18n.locale == 'en'" :to="'/properties/'+item.slug">
            <div class="image" style="height: 285px" :style="'background-image:url('+item.image.url+');background-size:cover;'">

                <div class="price clearfix">
                    <span class="tag pull-right">{{ item.price }}</span>
                </div>

                <span v-if="item.forRent"
                    class="tag_t"
                    :class="($i18n.locale == 'ar') ? 'hacen_algeria' : ''">
                    for Rent
                </span>

                <span v-if="item.forSale"
                    class="tag_t"
                    :class="($i18n.locale == 'ar') ? 'hacen_algeria' : ''">
                    for Sale
                </span>

                <span v-if="item.isFeatured"
                    class="tag_l"
                    :class="($i18n.locale == 'ar') ? 'hacen_algeria' : ''">
                    Featured
                </span>

            </div>
        </router-link>

        <router-link v-if="$i18n.locale == 'ar'" :to="'/properties/'+item.slug+'?lang=ar'">
            <div class="image" style="height: 285px" :style="'background-image:url('+item.image.url+');background-size:cover;'">

                <div class="price clearfix">
                    <span class="tag pull-right">{{ item.price }}</span>
                </div>

                <span v-if="item.forRent"
                      class="tag_t"
                      :class="($i18n.locale == 'ar') ? 'hacen_algeria' : ''">
                    for Rent
                </span>

                <span v-if="item.forSale"
                      class="tag_t"
                      :class="($i18n.locale == 'ar') ? 'hacen_algeria' : ''">
                    for Sale
                </span>

                <span v-if="item.isFeatured"
                      class="tag_l"
                      :class="($i18n.locale == 'ar') ? 'hacen_algeria' : ''">
                    Featured
                </span>

            </div>
        </router-link>
        <div class="proerty_content">
            <div style="height: 26vh;">
            <div class="proerty_text">
                <h3 class="captlize">
                    <router-link v-if="$i18n.locale == 'en'"
                                 :to="'/properties/'+item.slug"
                        :class="($i18n.locale == 'ar') ? 'hacen_algeria' : ''">
                        {{ ($i18n.locale == 'ar') ? item.title_ar : item.title_en }}
                    </router-link>
                    <router-link v-if="$i18n.locale == 'ar'"
                                 :to="'/properties/'+item.slug+'?lang=ar'"
                            :class="($i18n.locale == 'ar') ? 'hacen_algeria' : ''">
                        {{ ($i18n.locale == 'ar') ? item.title_ar : item.title_en }}
                    </router-link>
                </h3>
                <p 
                    :class="($i18n.locale == 'ar') ? 'hacen_algeria' : ''">
                    <i class="icon-icons74"></i>
                    {{ (item.location) ?($i18n.locale == 'ar') ? item.location.ar.title : item.location.en.title : '' }}
                </p>
            </div>

            <div class="property_meta transparent" style='padding-left: 20px'>
                <span>
                    <span v-if="item.icons[0]">
                        <img
                            :src="(item.icons[0].icon) ? item.icons[0].icon.image.url : ''"
                            :alt="(item.icons[0].icon) ?($i18n.locale == 'ar') ? item.icons[0].icon.image.ar.image_alt : item.icons[0].icon.image.en.image_alt : ''"
                            :title="(item.icons[0].icon) ?($i18n.locale == 'ar') ? item.icons[0].icon.image.ar.image_title : item.icons[0].icon.image.en.image_title : ''"
                            style="width:20px; height:20px">
                        {{ item.icons[0].value }}
                        {{ ($i18n.locale == 'ar') ? item.icons[0].icon.ar.title : item.icons[0].icon.en.title }}
                    </span>
                </span>
                <span>
                    <span v-if="item.icons[1]">
                        <img
                            :src="(item.icons[1].icon) ? item.icons[1].icon.image.url : ''"
                            :alt="(item.icons[1].icon) ?($i18n.locale == 'ar') ? item.icons[1].icon.image.ar.image_alt : item.icons[1].icon.image.en.image_alt : ''"
                            :title="(item.icons[1].icon) ?($i18n.locale == 'ar') ? item.icons[1].icon.image.ar.image_title : item.icons[1].icon.image.en.image_title : ''"
                            style="width:20px; height:20px">
                        {{ item.icons[1].value }}
                        {{ ($i18n.locale == 'ar') ? item.icons[1].icon.ar.title : item.icons[1].icon.en.title }}
                    </span>
                </span>
                <span>
                    <span v-if="item.icons[2]">
                        <img v-if="item.icons[2]"
                            :src="(item.icons[2].icon) ? item.icons[2].icon.image.url : ''"
                            :alt="(item.icons[2].icon) ?($i18n.locale == 'ar') ? item.icons[2].icon.image.ar.image_alt : item.icons[2].icon.image.en.image_alt : ''"
                            :title="(item.icons[2].icon) ?($i18n.locale == 'ar') ? item.icons[2].icon.image.ar.image_title : item.icons[2].icon.image.en.image_title : ''"
                            style="width:20px; height:20px">
                        {{ item.icons[2].value }}
                        {{ ($i18n.locale == 'ar') ? item.icons[2].icon.ar.title : item.icons[2].icon.en.title }}
                    </span>
                </span>
            </div>

            <div class="property_meta transparent" style='padding-left: 20px'>
                <span>
                    <span v-if="item.icons[3]">
                        <img
                            :src="(item.icons[3].icon) ? item.icons[3].icon.image.url : ''"
                            :alt="(item.icons[3].icon) ?($i18n.locale == 'ar') ? item.icons[3].icon.image.ar.image_alt : item.icons[3].icon.image.en.image_alt : ''"
                            :title="(item.icons[3].icon) ?($i18n.locale == 'ar') ? item.icons[3].icon.image.ar.image_title : item.icons[3].icon.image.en.image_title : ''"
                            style="width:20px; height:20px">
                        {{ item.icons[3].value }}
                        {{ ($i18n.locale == 'ar') ? item.icons[3].icon.ar.title : item.icons[3].icon.en.title }}
                    </span>
                </span>
                <span>
                    <span v-if="item.icons[4]">
                        <img
                            :src="(item.icons[4].icon) ? item.icons[4].icon.image.url : ''"
                            :alt="(item.icons[4].icon) ?($i18n.locale == 'ar') ? item.icons[4].icon.image.ar.image_alt : item.icons[4].icon.image.en.image_alt : ''"
                            :title="(item.icons[4].icon) ?($i18n.locale == 'ar') ? item.icons[4].icon.image.ar.image_title : item.icons[4].icon.image.en.image_title : ''"
                            style="width:20px; height:20px">
                        {{ item.icons[4].value }}
                        {{ ($i18n.locale == 'ar') ? item.icons[4].icon.ar.title : item.icons[4].icon.en.title }}
                    </span>
                </span>
                <span>
                    <span v-if="item.icons[5]">
                        <img
                            :src="(item.icons[5].icon) ? item.icons[5].icon.image.url : ''"
                            :alt="(item.icons[5].icon) ?($i18n.locale == 'ar') ? item.icons[5].icon.image.ar.image_alt : item.icons[5].icon.image.en.image_alt : ''"
                            :title="(item.icons[5].icon) ?($i18n.locale == 'ar') ? item.icons[5].icon.image.ar.image_title : item.icons[5].icon.image.en.image_title : ''"
                            style="width:20px; height:20px">
                        {{ item.icons[5].value }}
                        {{ ($i18n.locale == 'ar') ? item.icons[5].icon.ar.title : item.icons[5].icon.en.title }}
                    </span>
                </span>
            </div>
            </div>

            <div class="favroute clearfix top30">
                <p class="pull-md-left">{{ item.created_at }} &nbsp; <i class="icon-calendar2"></i></p>
            </div>

        </div>
    </div>

</template>

<script>    
export default {
    name: 'PropertyCard',
    props: ['item'],
    data() {
        return {
            //
        }
    }
}
</script>
